<template>
    <div>
        <nav-bar></nav-bar>
        <div>
            <img src="../assets/images/series-details/banner.png" alt="" class="img">
        </div>
        <div style="background:#f2f2f2 ;">
            <ul class="flex-between-wrap container product-content">
                <li class="product-list"  v-for="(item,index) in productList" :key="index" v-lazy:background-image="{src: item.images}">
                    <a class="item-content-a" @click="goTo(item.arguments)"></a>
                    <div class="product-list-title">{{item.title}}</div>
                    <div class="product-list-description">{{item.description}}</div>
                    <div class="product-list-button pointer">
                        <span @click="goTo(item.arguments)" class="a_green">了解更多 ></span>
                        <span class="a_green m-l-20" @click="go3D(item.arguments)">3D展厅 ></span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  　import { reactive, onMounted,toRefs ,ref,nextTick, computed } from 'vue'
import navBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import { useRouter } from 'vue-router';
  export default {
    name: 'Home',
    components: {
      navBar,
      Footer
    },
  
    setup() { 
        const router = useRouter()
      const useData = reactive({
          productList: [
            {
                images: require('../assets/images/ad/series-details.png'),
                arguments: "ad",
                title: "SUMGKEE AD 系列",
                description: "为用户提供一站式的、端到端的高能效、高弹性的解决方案, 适用于小型企业日常办公应用",
            },
            {
                images: require('../assets/images/v2/series-details.png'),
                arguments: "v2",
                title: "SUMGKEE V2 系列",
                description: "为用户提供一站式的、端到端的高能效、高弹性的解决方案, 适用于小型企业专业级项目应用，性能强悍。 ",
            },   
            {
                images: require('../assets/images/v4/series-details.png'),
                arguments: "v4",
                title: "SUMGKEE V4 系列",
                description: "为用户提供一站式的、端到端的高能效、高弹性的解决方案, 适用于中型企业专业级项目应用。 ",
            },
            {
                images: require('../assets/images/v6/series-details.png'),
                arguments: "v6",
                title: "SUMGKEE V6 系列",
                description: "为用户提供一站式的、端到端的高能效、高弹性的解决方案, 适用于中型企业专业级项目应用。 ",
            },
            {
                images: require('../assets/images/v8/series-details.png'),
                arguments: "v8",
                title: "SUMGKEE V8 系列",
                description: "为用户提供一站式的、端到端的高能效、高弹性的解决方案, 适用于大型企业和数据中心应用。 ",
              }
          ]
      })
      const goTo = (id) => {
      router.push({ path: '/Item', query: { id } })
    }
    const go3D= (name) => {
      router.push({ path: '/sumgkee3d', query: { name } })
      }
    return {
        ...toRefs(useData),
        goTo,
        go3D
    }
  }
}
  </script>
  <style scoped>
.product-list{
    width: 583px;height: 560px;
    margin-bottom: 36px;
    background: no-repeat center center;
    background-size: cover;

}
.product-list-title{
    padding-top: 390px;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
}
.product-list-description{
    font-size: 16px;
    text-align: center;
    padding: 12px 78px;
}
.product-list-button{
    text-align: center;
    color: #666666;
    font-size: 14px;
    z-index: 4;
    position: relative;
}
.product-content{
    padding: 56px 0px;
    position: relative;
}
.product-content li{
    list-style: none;
}
</style>
  